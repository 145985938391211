import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram } from 'react-icons/fa';
import { Box, Button, TextField, Typography, Grid, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import '../styles/SupportPage.css'; // Ensure this path is correct
import contact_Img from "../assets/testimonal/Untitled design (10).png"

const SupportPage = () => {
  const [phoneError, setPhoneError] = useState(''); // State for handling phone number validation
  const [responseMessage, setResponseMessage] = useState(''); // State for response message
  const [loading, setLoading] = useState(false); // State for handling loader
  const [submitted, setSubmitted] = useState(false); // State for disabling submit after form submission
  const [showPopup, setShowPopup] = useState(false); // State for showing the Dialog popup
  const [popupType, setPopupType] = useState('success'); // State to determine popup style

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    phone: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    // If the input is for phone, ensure only digits are entered and limit to 10 characters
    if (name === 'phone') {
      // Remove any non-digit characters
      const sanitizedValue = value.replace(/\D/g, '');
      // Limit to 10 digits
      const limitedValue = sanitizedValue.slice(0, 10);
      setFormData((prevFormData) => ({ ...prevFormData, [name]: limitedValue }));

      // Real-time validation
      if (limitedValue.length !== 10) {
        setPhoneError('Mobile number must be exactly 10 digits.');
      } else {
        setPhoneError('');
      }
    } else {
      setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Reset errors
    setPhoneError('');

    // Validate phone number
    const phoneRegex = /^\d{10}$/;
    if (!phoneRegex.test(formData.phone)) {
      setPhoneError('Mobile number must be exactly 10 digits.');
      return;
    }

    // Proceed with form submission
    const apiUrl = 'https://finle-api-gateway.azurewebsites.net/user-service/addContactUs';
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString();

    const requestBody = {
      txnDate: formattedDate,
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
      category: formData.subject,
      remarks: formData.message
    };

    const token = localStorage.getItem('token');
    setLoading(true); // Show loader
    setSubmitted(true); // Disable further form submission

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token
        },
        body: JSON.stringify(requestBody)
      });
      const responseData = await response.json();
      setLoading(false); // Hide loader

      if (response.ok) {
        if (responseData.responseStatus.statusCode === 0) {
          setResponseMessage('Form submitted successfully!');
          setPopupType('success'); // Set popup type for success
          setFormData({
            name: '',
            email: '',
            phone: '',
            subject: '',
            message: ''
          });
        } else {
          setResponseMessage('Form submission failed. Please try again.');
          setPopupType('error'); // Set popup type for error
        }
      } else {
        setResponseMessage('Form submission failed. Please try again.');
        setPopupType('error'); // Set popup type for error
        console.error(response.statusText);
      }

      setShowPopup(true); // Show the Dialog popup

    } catch (error) {
      setLoading(false);
      console.error(error);
      setResponseMessage('An error occurred. Please try again.');
      setPopupType('error'); // Set popup type for error
      setShowPopup(true); // Show error message in popup
    }
  };

  // Close the Dialog
  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <Container fluid className="support-page">
      <Typography variant="h3" align="center" sx={{ m: 2, fontWeight: "bold", color: "#010438" }}>
        Get in Touch with Us
      </Typography>
      <div className='disp_cl'>
      <Row className="d-flex align-items-center ">
        {/* Left Column for Form */}
        <Col lg={12} className="p-2">
          <Box sx={{ padding: '20px', maxWidth: '500px', margin: '0 auto' }}>
            <form onSubmit={handleSubmit} className="form-overlay">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Enter Name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                    disabled={submitted}
                    InputProps={{
                      sx: {
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#010438"
                        },
                      },
                    }}
                    InputLabelProps={{
                      sx: {
                        color: "#010438",
                        "&.Mui-focused": {
                          color: "#010438"
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    type="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    disabled={submitted}
                    InputProps={{
                      sx: {
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#010438"
                        },
                      },
                    }}
                    InputLabelProps={{
                      sx: {
                        color: "#010438",
                        "&.Mui-focused": {
                          color: "#010438"
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Mobile Number"
                    name="phone"
                    type="tel" // Changed from 'number' to 'tel' for better phone input handling
                    value={formData.phone}
                    onChange={handleChange}
                    required
                    disabled={submitted}
                    error={!!phoneError}
                    helperText={phoneError}
                    inputProps={{
                      maxLength: 10, // Limit input to 10 digits
                      pattern: "[0-9]{10}"
                    }}
                    InputProps={{
                      sx: {
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#010438"
                        },
                      },
                    }}
                    InputLabelProps={{
                      sx: {
                        color: "#010438",
                        "&.Mui-focused": {
                          color: "#010438"
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Subject"
                    name="subject"
                    type="text"
                    value={formData.subject}
                    onChange={handleChange}
                    required
                    disabled={submitted}
                    InputProps={{
                      sx: {
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#010438"
                        },
                      },
                    }}
                    InputLabelProps={{
                      sx: {
                        color: "#010438",
                        "&.Mui-focused": {
                          color: "#010438"
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    multiline
                    rows={2}
                    disabled={submitted}
                    InputProps={{
                      sx: {
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#010438"
                        },
                      },
                    }}
                    InputLabelProps={{
                      sx: {
                        color: "#010438",
                        "&.Mui-focused": {
                          color: "#010438"
                        },
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Box sx={{ mt: 4, textAlign: 'center' }}>
                <Button
                  type="submit"
                  className="btn-submit"
                  variant="contained"
                  color="primary"
                  sx={{ padding: '10px 30px' }}
                  disabled={loading || submitted}
                >
                  {loading ? <CircularProgress size={24} /> : 'Submit'}
                </Button>
              </Box>
            </form>
          </Box>
        </Col>

        
        </Row>
        {/* Right Column for Background Image */}

        <img className='contact_img' src= "/support_img.png" />
        </div>
      {/* Dialog for response message */}
      <Dialog
        open={showPopup}
        onClose={handleClosePopup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {popupType === 'success' ? 'Success' : 'Error'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {responseMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePopup} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default SupportPage;
