import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import FailureIcon from '../assets/images/FailureIcon.png';
import finleLogo2 from '../assets/logo/finlelogo2.png';

const FailurePage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Extract query params
  const getQueryParams = () => {
    const params = new URLSearchParams(location.search);
    const req = params.get('req'); // Possible values: Esign, Video-Kyc, Digilocker, Enach
    const flag = params.get('flag') === 'false'; // Check if flag is false
    return { req, flag };
  };

  const { req, flag } = getQueryParams();

  // Redirect if flag is true
  useEffect(() => {
    if (!flag) {
      navigate('/'); // Redirect to home or error page
    }
  }, [flag, navigate]);

  // Determine dynamic text
  const getDynamicText = (req) => {
    switch (req) {
      case 'Esign':
        return 'E-Sign';
      case 'Video-Kyc':
        return 'Video-KYC';
      case 'Digilocker':
        return 'Digi-Locker';
      case 'Enach':
        return 'E-Nach';
      default:
        return 'Service'; // Fallback text
    }
  };

  return (
    <div
      style={{
        position: 'relative',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: '1rem',
        gap: '2rem',
      }}
    >
      <div>
        <img
          src={finleLogo2}
          alt=""
          style={{ height: '3.5rem', objectFit: 'contain' }}
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img
          src={FailureIcon}
          alt=""
          style={{ height: '4rem', objectFit: 'contain' }}
        />
        <h3 style={{ color: 'red' }}>Failed</h3>
        <p style={{ color: '#9B9A97' }}>
          {`${getDynamicText(req)} unsuccessful. Please try again. You may close the tab now.`}
        </p>
      </div>
      <footer
        style={{
          position: 'fixed',
          bottom: 0,
          width: '100%',
          backgroundColor: '#020438',
          color: 'white',
          padding: '1rem',
          textAlign: 'center',
        }}
      >
        Copyright © Finle
      </footer>
    </div>
  );
};

export default FailurePage;
