import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SuccessIcon from "../assets/images/SuccessIcon.png";
import finleLogo2 from "../assets/logo/finlelogo2.png";

const SuccessPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Extract query params
  const getQueryParams = () => {
    const params = new URLSearchParams(location.search);
    const req = params.get("req"); // Possible values: Esign, Video-Kyc, Digilocker, Enach
    const flag = params.get("flag") === "true"; // Convert flag to boolean
    return { req, flag };
  };

  const { req, flag } = getQueryParams();

  // Redirect if flag is false
  useEffect(() => {
    if (!flag) {
      navigate("/"); // Redirect to home or error page
    }
  }, [flag, navigate]);

  // Determine dynamic text
  const getDynamicText = (req) => {
    switch (req) {
      case "Esign":
        return "E-Sign";
      case "Video-Kyc":
        return "Video-KYC";
      case "Digilocker":
        return "Digi-Locker";
      case "Enach":
        return "E-Nach";
      default:
        return "Service"; // Fallback text
    }
  };

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        padding: "1rem",
        gap: "2rem",
        overflow: "hidden",
        height: "100vh",
      }}
    >
      <div>
        <img
          src={finleLogo2}
          alt=""
          style={{ height: "3.5rem", objectFit: "contain" }}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={SuccessIcon}
          alt=""
          style={{ height: "6rem", objectFit: "contain" }}
        />
        <h3 style={{ color: "#40c057" }}>Success</h3>
        <p style={{ color: "#9B9A97" }}>
          {`${getDynamicText(req)} successful. You may close the tab now.`}
        </p>
      </div>
      <footer
        style={{
          position: "fixed",
          bottom: "0rem",
          width: "100%",
          backgroundColor: "#020438",
          color: "white",
          padding: "1rem",
          textAlign: "center",
        }}
      >
        Copyright © Finle
      </footer>
    </div>
  );
};

export default SuccessPage;
